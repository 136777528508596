<template>
  <el-dialog
    :visible="visible"
    :showClose="false"
    :modal="false"
    lock-scroll
    style="position: absolute"
  >
    <!-- 内容  -->
    <div   class="window-inner" result="" scence-idx="0">
      <img src="../assets/img/zoomOut.png" class="zoomOut" @click="onClose" />
      <img  src="../assets/img/12-6-1-1.png" style="width: 30rem;">
      <div  class="button close" @click="openClose" :style="{'--aa': aa,}"></div>
      <div  class="light power"></div>
      <div  class="light" :class="{run:i!=1}"></div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    trans: {
      type: Boolean,
      default: false,
    },
    open:{
      type: Boolean,
      default: false,
    },
    keywordOption: {
      type: Object,
      default: () => {},
    },
    close: {
      type: Function,
    },
  },
  watch:{
    i:{
      immediate:true,
      handler(val){
        let arr = ['rotate(-45deg)','rotate(0deg)','rotate(45deg)']
        this.aa = arr[val]

      }
    }
  },
  data() {
    return {
      keyCode: "f6",
      isShow: false,
      title:'火警信息',
      aa:"rotate(0deg)",
      flag:false,
      i:1,
    };
  },
  
 
  methods: {
    openClose(){
      let arr = ['rotate(-45deg)','rotate(0deg)','rotate(45deg)']
      if(this.i==0){
        this.flag=true
      }
      if(this.i==2){
        this.flag=false
      }
      if(this.flag){
        this.i++
      }else {
        this.i--
      }
      if(this.i!=1){
        this.$parent.play2('12-5-4电动排烟窗开启.mp3')
      
      }
      this.aa = arr[this.i]
      this.$emit('open',this.i)
    },
    show() {
      this.isShow = true;
    },
    onSwitch(){
      this.trans = !this.trans;

    },

    onDown(parms) {
      if(!this.keywordOption[parms]) return
      this.keyCode = parms;
      this.title = this.keywordOption[parms]['title']
      this.$parent.play2('12-6-2挡烟垂壁上升声.mp3')
    },
    onClose() {

      this.close && this.close();
      this.$parent.play2('zoomin.mp3')
    },
  },
};
</script>

<style lang="less" scoped>
.window-inner {
  position: relative;
  border: .6rem solid #fff;
  border-radius: 1.2rem;
}
.window-inner .light {
  position: absolute;
  top: 12.4%;
  height: 8rem;
  width: 8rem;
  left: 14.7%;
}
.window-inner .light.power {
  background: url('https://bankaoedu.com/files/xc/red_open.png') no-repeat;
  background-size: 100% auto;
  opacity: .3;
}
.window-inner .light.run {
  left: 56.3%;
  background: url('https://bankaoedu.com/files/xc/green_open.png') no-repeat;
  background-size: 100% auto;
}
.window-inner .button {
  position: absolute;
  height: 3.75rem;
  width: 3.75rem;
  top: 52.120944%;
  left: 42.3%;
  border-radius: 3.75rem;
  background-color: #333;
  -webkit-filter: drop-shadow(.0625rem .0625rem .125rem #000);
  filter: drop-shadow(.0625rem .0625rem .125rem black);
  z-index: 1;
  -webkit-box-shadow: -0.1875rem -0.1875rem 0.375rem hsla(0,0%,100%,.5137254901960784), 0.1875rem 0.1875rem 0.3125rem rgba(94,104,121,.688), inset 0.0625rem 0.0625rem 0.125rem hsla(0,0%,100%,.8);
  box-shadow: -0.1875rem -0.1875rem 0.375rem hsla(0,0%,100%,.5137254901960784), 0.1875rem 0.1875rem 0.3125rem rgba(94,104,121,.688), inset 0.0625rem 0.0625rem 0.125rem hsla(0,0%,100%,.8);
}
.window-inner .button:after {
  position: absolute;
  content: "";
  height: 2.5rem;
  width: .5rem;
  border-radius: .5rem;
  background-color: #fff;
  bottom: 1.8rem;
  left: 50%;
  margin-left: 0;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform .5s linear;
  transition: -webkit-transform .5s linear;
  transition: transform .5s linear;
  transition: transform .5s linear, -webkit-transform .5s linear;
  transform: var(--aa);

}
.zoomOut {
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;

}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}



// 漂移时间 5.7


</style>
